/* purgecss start ignore */
@import "tailwindcss/base";
@import "tailwindcss/components";
/* purgecss end ignore */
@import "tailwindcss/utilities";


.text-shadow {
  text-shadow: rgba(50, 50, 93, 0.3) 0px 5px 15px,
    rgba(0, 0, 0, 0.1) 0px 2px 2px;
}

.dark-mode {
  --color-bg-primary: theme("colors.realgray.900");
  --color-bg-secondary: theme("colors.realgray.800");
  --color-bg-card: theme("colors.realgray.800");
  --color-text-primary: theme("colors.realgray.300");
  --color-text-secondary: theme("colors.realgray.500");
  --color-text-tertiary: theme("colors.realgray.700");
  --color-border-primary: theme("colors.realgray.700");
  --color-href-base: theme("colors.pink.600");
  --color-href-hover: theme("colors.pink.300");
  --color-href-active: theme("colors.pink.400");
  --color-text-success: theme("colors.green.300");
}

.light-mode {
  --color-bg-primary: theme("colors.white");
  --color-bg-secondary: theme("colors.gray.200");
  --color-bg-card: theme("colors.white");
  --color-text-primary: theme("colors.gray.900");
  --color-text-secondary: theme("colors.gray.600");
  --color-text-tertiary: theme("colors.gray.300");
  --color-border-primary: theme("colors.gray.300");
  --color-href-base: theme("colors.pink.600");
  --color-href-hover: theme("colors.pink.800");
  --color-href-active: theme("colors.pink.700");
  --color-text-success: theme("colors.green.700");
}